import React, { useRef, useEffect, useState } from 'react'
import Wrapper from '../Wrap'
import { RichText } from 'prismic-reactjs'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const LogoList = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
          y: 60,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          duration: 1.5,
          scrollTrigger: {
            id: `logo-list-${index + 1}`,
            trigger: el,
            start: 'top 90%',
            end: 'top top',
            once: true,
          },
        }
      )
    })
  }, [])

  const renderedImages = slice.items.map((element, index) => (
    <div
      className="logo-list__image-wrapper"
      key={`logo-list-${index}`}
      ref={addToRefs}>
      <div
        className={`logo-list__image-container ${
          element.background ? element.background : 'no-background'
        }`}>
        <img
          className="logo-list__image lazyload"
          alt={element.logo ? element.logo.alt : ''}
          data-src={element.logo ? element.logo.url : ''}
        />
      </div>
    </div>
  ))

  const noPaddingTitle = slice.primary.title.raw ? '' : '-pad-top-none'
  const moduleClasses = `module -theme-white ${noPaddingTitle}`

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  return (
    <section className={moduleClasses}>
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <Wrapper className="logo-list typ">
        <RichText render={slice.primary.title.raw || []} />
        <div className="logo-list__container">{renderedImages}</div>
      </Wrapper>
    </section>
  )
}

export default LogoList
