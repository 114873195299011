import React, { useRef, useEffect, useState } from 'react'
import Wrapper from '../Wrap'
import Icon from '../../images/svg/far-beyond-dna.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const DNA = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      const dnaTL = gsap.timeline({
        duration: 0.3,
        paused: true,
        scrollTrigger: {
          id: `dna-${index + 1}`,
          trigger: el,
          start: 'top 80%',
          end: 'top top',
          once: true,
        },
      })
      const children = el.children[0].children
      const greenCircle = el.querySelector('.js-dna-circle-green-circle')
      const greenRing = el.querySelector('.js-dna-circle-green-ring')
      const greenLine = el.querySelector('.js-dna-circle-green-line')
      const greenText = el.querySelector('.js-dna-circle-green-text')

      const pinkCircle = el.querySelector('.js-dna-circle-pink-circle')
      const pinkRing = el.querySelector('.js-dna-circle-pink-ring')
      const pinkLine = el.querySelector('.js-dna-circle-pink-line')
      const pinkText = el.querySelector('.js-dna-circle-pink-text')

      const blueCircle = el.querySelector('.js-dna-circle-blue-circle')
      const blueRing = el.querySelector('.js-dna-circle-blue-ring')
      const blueLine = el.querySelector('.js-dna-circle-blue-line')
      const blueText = el.querySelector('.js-dna-circle-blue-text')

      const greenVibrantCircle = el.querySelector(
        '.js-dna-circle-vibrant-green-circle'
      )
      const greenVibrantRing = el.querySelector(
        '.js-dna-circle-vibrant-green-ring'
      )
      const greenVibrantLine = el.querySelector(
        '.js-dna-circle-vibrant-green-line'
      )
      const greenVibrantText = el.querySelector(
        '.js-dna-circle-vibrant-green-text'
      )

      const yellowCircle = el.querySelector('.js-dna-circle-yellow-circle')
      const yellowRing = el.querySelector('.js-dna-circle-yellow-ring')
      const yellowLine = el.querySelector('.js-dna-circle-yellow-line')
      const yellowText = el.querySelector('.js-dna-circle-yellow-text')

      const redCircle = el.querySelector('.js-dna-circle-red-circle')
      const redRing = el.querySelector('.js-dna-circle-red-ring')
      const redLine = el.querySelector('.js-dna-circle-red-line')
      const redText = el.querySelector('.js-dna-circle-red-text')

      dnaTL
        .set(children, { autoAlpha: 1 })
        .set(
          [
            greenRing,
            greenCircle,
            greenLine,
            pinkRing,
            pinkCircle,
            pinkLine,
            blueRing,
            blueCircle,
            blueLine,
            greenVibrantRing,
            greenVibrantCircle,
            greenVibrantLine,
            yellowRing,
            yellowCircle,
            yellowLine,
            redRing,
            redCircle,
            redLine,
          ],
          { scale: 0, transformOrigin: 'center' }
        )
        .to(greenRing, {
          scale: 1,
          transformOrigin: 'center',
          ease: 'back.out(1.3)',
        })
        .to(
          greenCircle,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.2'
        )
        .from(greenText, { autoAlpha: 0 }, '<0.2')
        .to(greenLine, { scale: 1 }, '<0.3')
        .to(
          pinkRing,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.3'
        )
        .to(
          pinkCircle,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.2'
        )
        .from(pinkText, { autoAlpha: 0 }, '<0.2')
        .to(pinkLine, { scale: 1 }, '<0.3')
        .to(
          blueRing,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.3'
        )
        .to(
          blueCircle,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.2'
        )
        .to(blueLine, { scale: 1 }, '<0.3')
        .from(blueText, { autoAlpha: 0 }, '<0.2')
        .to(
          greenVibrantRing,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.3'
        )
        .to(
          greenVibrantCircle,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.2'
        )
        .to(greenVibrantLine, { scale: 1 }, '<0.3')
        .from(greenVibrantText, { autoAlpha: 0 }, '<0.2')
        .to(
          yellowRing,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.3'
        )
        .to(
          yellowCircle,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.2'
        )
        .to(yellowLine, { scale: 1 }, '<0.3')
        .from(yellowText, { autoAlpha: 0 }, '<0.2')
        .to(
          redRing,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.3'
        )
        .to(
          redCircle,
          {
            scale: 1,
            transformOrigin: 'center',
            ease: 'back.out(1.3)',
          },
          '<0.2'
        )
        .to(redLine, { scale: 1 }, '<0.3')
        .from(redText, { autoAlpha: 0 }, '<0.2')
    })
  }, [])

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  return (
    <section className="module -theme-white -pad-top-none">
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <div ref={addToRefs}>
        <Wrapper className="dna typ">
          <Icon className="dna-diagram" />
        </Wrapper>
      </div>
    </section>
  )
}

export default DNA
