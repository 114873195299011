import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const TwoColumnText = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      const firstElement = el.children[0]
      const secondElement = el.children[1]
      let animateElements = [
        firstElement ? firstElement : null,
        secondElement ? secondElement : null,
      ].filter(function (el) {
        return el != null
      })
      gsap.fromTo(
        animateElements,
        {
          autoAlpha: 0,
          y: 60,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          stagger: 0.2,
          duration: 1.5,
          scrollTrigger: {
            id: `two-column-${index + 1}`,
            trigger: el,
            start: 'top 80%',
            end: 'top top',
            once: true,
          },
        }
      )
    })
  }, [])

  const columns = slice.items.map((element, index) => (
    <div className="two-column__column" key={`column-${index}`} ref={addToRefs}>
      <h4>{element.title.text}</h4>
      <div className="two-column__column-column typ">
        <RichText
          render={element.content.raw || []}
          serializeHyperlink={GatsbyLink}
        />
      </div>
    </div>
  ))

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  const theme = slice.primary.theme_colour
    ? slice.primary.theme_colour
    : 'black'
  const moduleClasses = `module -theme-${theme}`
  return (
    <section className={moduleClasses}>
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <div>
        <Wrapper className="two-column typ">
          {slice.primary.title.text ? (
            <h2 className="h1" ref={addToRefs}>
              {slice.primary.title.text}
            </h2>
          ) : (
            ''
          )}
          <div className="two-column__columns">{columns}</div>
        </Wrapper>
      </div>
    </section>
  )
}

export default TwoColumnText
