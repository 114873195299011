import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const InfoWithImage = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      const children = el.children
      gsap.fromTo(
        children,
        {
          autoAlpha: 0,
          y: 60,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          stagger: 0.2,
          duration: 1.5,
          scrollTrigger: {
            id: `general-content-${index + 1}`,
            trigger: children,
            start: 'top 80%',
            end: 'top top',
            once: true,
          },
        }
      )
    })
  }, [])

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  const featuredImage = slice.primary.featured_image
  const textPosition = slice.primary.text_position
    ? `info-with-image__container typ ${slice.primary.text_position}`
    : 'info-with-image__container typ left'

  const theme = slice.primary.theme_colour
    ? slice.primary.theme_colour
    : 'black'
  const moduleClasses = `module -theme-${theme}`

  return (
    <section className={moduleClasses}>
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <Wrapper className="info-with-image -contained">
        <div className={textPosition}>
          <div className="featured-image">
            <img
              className="lazyload"
              data-src={featuredImage ? featuredImage.fixed.src : ''}
              alt={featuredImage ? featuredImage.alt : ''}
            />
          </div>
          <div className="text-content" ref={addToRefs}>
            <RichText render={slice.primary.section_title.raw || []} />
            <RichText
              render={slice.primary.text.raw || []}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  )
}

export default InfoWithImage
