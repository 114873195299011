import React from 'react'
import Wrapper from '../Wrap'
import LoadingSpinner from '../LoadingSpinner'

import Isotope from 'isotope-layout/js/isotope'
import 'isotope-packery'
import OnImagesLoaded from 'react-on-images-loaded'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
}

class ImageWallGrid extends React.Component {
  constructor(props) {
    super(props)
    this.handleLoad = this.handleLoad.bind(this)
    this.state = {
      showImages: false,
    }
  }

  componentDidMount() {
    this.handleLoad()
    console.log('component did mount')
  }

  handleLoad() {
    this.layout = new Isotope('.image-wall__list', {
      itemSelector: '.image-wall__list-tile',
      // stagger: 30,
      percentPosition: true,
      // layoutMode: 'masonry',
      masonry: {
        columnWidth: '.grid-sizer',
        horizontalOrder: false,
      },
    })
  }

  imagesHaveLoaded() {
    this.layout.layout()

    setTimeout(() => {
      this.setState({ showImages: true })
      const trigger = [...document.querySelectorAll('.js-image-trigger')]
      trigger.map((el, index) => {
        const image = el.querySelector('.image-wall__list-tile__image')
        gsap.fromTo(
          image,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            ease: 'power4.out',
            duration: 1.5,
            stagger: 0.5,
            scrollTrigger: {
              id: `image-wall-${index + 1}`,
              trigger: el,
              start: 'top 90%',
              end: 'top top',
              once: true,
            },
          }
        )
      })
    }, 2000)
  }

  layoutRefresh() {
    this.layout.layout()
    ScrollTrigger.refresh()
  }

  render() {
    const childElements = this.props.childElements

    return (
      <section className="module work-module -full-height">
        <div className="image-wall__list-wrapper">
          <div className="container image-wall__list" id="#imageWallList">
            <OnImagesLoaded
              onLoaded={this.imagesHaveLoaded.bind(this)}
              onTimeout={this.imagesHaveLoaded.bind(this)}
              timeout={5000}>
              {this.state.showImages ? null : (
                <LoadingSpinner title="Loading..." />
              )}
              <div className="grid-sizer"></div>
              {childElements.map((element, index) => (
                <div
                  key={`item-${index}`}
                  className={`image-wall__list-tile image-wall__list-tile__${element.tile_size} || js-image-trigger`}>
                  <div
                    className="image-wall__list-tile__image"
                    style={{
                      opacity: this.state.showImages ? 1 : 0,
                    }}>
                    <img
                      onLoad={this.layoutRefresh.bind(this)}
                      className="image-wall__list-tile__image-img lazyload"
                      alt={element.image.alt ? element.image.alt : ''}
                      data-src={
                        element.image.url ? element.image.fluid.src : ''
                      }
                      src={
                        element.image.url
                          ? `${element.image.fluid.src}&q=5`
                          : ''
                      }
                    />
                  </div>
                </div>
              ))}
            </OnImagesLoaded>
          </div>
        </div>
      </section>
    )
  }
}

const ImageWall = ({ slice }) => {
  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  return (
    <section className="module -theme-white -pad-top-none">
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <Wrapper className="typ -full">
        {slice.primary.title.text ? (
          <div className="image-wall__title-container">
            <h1>{slice.primary.title.text}</h1>
          </div>
        ) : (
          ''
        )}
        <ImageWallGrid childElements={slice.items} />
      </Wrapper>
    </section>
  )
}

export default ImageWall
