import React from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'

const TextInfo = ({ slice }) => {
  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  return (
    <section className="module">
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <Wrapper className="text-info -contained">
        <div className="text-info__container typ">
          <div className="left-column">
            <RichText render={slice.primary.section_title.raw || []} />
            <RichText
              render={slice.primary.left_column_text.raw || []}
              serializeHyperlink={GatsbyLink}
            />
          </div>
          <div className="right-column">
            <RichText
              render={slice.primary.right_column_text.raw || []}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  )
}

export default TextInfo
