import React from 'react'
import '../utils/@slightlyoff/lite-vimeo'

export class ResponsivePlayer extends React.Component {
  constructor(props) {
    super(props)

    this.home = this.props.home

    this.state = {
      autoload: this.home,
      autoplay: this.home,
    }
  }

  render() {
    const video = this.props.url

    if (typeof window !== 'undefined') {
      return (
        <>
          <lite-vimeo videoid={video}></lite-vimeo>
        </>
      )
    } else {
      return (
        <div>
          <h1>Not loading</h1>
        </div>
      )
    }
  }
}
