import React from 'react'
import { RichText } from 'prismic-reactjs'
import Wrapper from '../Wrap'

const FullWidthImage = ({ slice }) => {
  // const hash = slice.primary.url_hash.raw[0]
  //   ? slice.primary.url_hash.raw[0].text
  //   : ''
  const image = slice.primary.image

  return (
    <section className="module -pad-top-none">
      {/* {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )} */}
      <Wrapper className="full-width-image -full">
        <img
          className="lazyload"
          data-src={image ? image.fluid.src : ''}
          data-srcset={image ? image.fluid.srcSet : ''}
          alt={image ? image.alt : ''}
        />
        {slice.primary.image_title.text ||
        slice.primary.image_description.text ? (
          <div className="video-embed__content">
            {slice.primary.image_title.text ? (
              <div className="video-embed__title">
                <RichText render={slice.primary.image_title.raw || []} />
              </div>
            ) : (
              ''
            )}
            {slice.primary.image_description.text ? (
              <div className="video-embed__description">
                <RichText render={slice.primary.image_description.raw || []} />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </Wrapper>
    </section>
  )
}

export default FullWidthImage
