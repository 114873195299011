import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import SectionFullHeight from '../SectionFullHeight'
import Wrapper from '../Wrap'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const HeroImageWithText = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      const childrenOne = el.children[0]
      const childrenTwo = el.children[1]?.children
      const childrenThree = el.children[2]?.children
      if (childrenOne) {
        gsap.fromTo(
          childrenOne,
          {
            autoAlpha: 0,
            y: 60,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: 'power4.out',
            stagger: 0.2,
            duration: 1.5,
            scrollTrigger: {
              id: `general-content-${index + 1}`,
              trigger: childrenOne,
              start: 'top 80%',
              end: 'top top',
              once: true,
            },
          }
        )
      }

      if (childrenTwo) {
        gsap.fromTo(
          childrenTwo,
          {
            autoAlpha: 0,
            y: 60,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: 'power4.out',
            stagger: 0.2,
            duration: 1.5,
            scrollTrigger: {
              id: `general-content-${index + 1}`,
              trigger: childrenTwo,
              start: 'top 80%',
              end: 'top top',
              once: true,
            },
          }
        )
      }

      if (childrenThree) {
        gsap.fromTo(
          childrenThree,
          {
            autoAlpha: 0,
            y: 60,
          },
          {
            autoAlpha: 1,
            y: 0,
            ease: 'power4.out',
            stagger: 0.2,
            duration: 1.5,
            scrollTrigger: {
              id: `general-content-${index + 1}`,
              trigger: childrenThree,
              start: 'top 80%',
              end: 'top top',
              once: true,
            },
          }
        )
      }
    })
  }, [])

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  const heroImage = slice.primary.image
  const heroTitle = slice.primary.title.raw[0].text
  return (
    <SectionFullHeight className="module hero-with-text">
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <Wrapper className="hero-image-content">
        <div className="hero-image-content__container typ">
          <div className="hero-image-content__image">
            <img
              className="lazyload"
              data-src={heroImage ? heroImage.url : ''}
              alt={heroImage ? heroImage.alt : ''}
            />
          </div>
          <div className="hero-image-content__content" ref={addToRefs}>
            {heroTitle ? <h1>{heroTitle}</h1> : ''}
            <div className="hero-image-content__content-column">
              <RichText
                render={slice.primary.content.raw || []}
                serializeHyperlink={GatsbyLink}
              />
            </div>
            {slice.primary.content_right.raw[0] ? (
              <div className="hero-image-content__content-column">
                <RichText
                  render={slice.primary.content_right.raw || []}
                  serializeHyperlink={GatsbyLink}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Wrapper>
    </SectionFullHeight>
  )
}

export default HeroImageWithText
