import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import Wrapper from '../Wrap'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const Blockquote = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      const children = el.children[0].children
      gsap.fromTo(
        children,
        {
          autoAlpha: 0,
          y: 60,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          duration: 1.5,
          scrollTrigger: {
            id: `blockquote-${index + 1}`,
            trigger: el,
            start: 'top 80%',
            end: 'top top',
            once: true,
          },
        }
      )
    })
  }, [])

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  const theme = slice.primary.theme_colour
    ? slice.primary.theme_colour
    : 'black'
  const moduleClasses = `module -theme-${theme}`
  const quote = slice.primary.text.raw[0].text
  return (
    <section className={moduleClasses}>
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <div ref={addToRefs}>
        <Wrapper className="blockquote typ">
          <div className="blockquote__wrapper">
            <h2 className="blockquote">{quote}</h2>
            {slice.primary.cite ? (
              <h6>— {slice.primary.cite.raw[0].text}</h6>
            ) : (
              ''
            )}
          </div>
        </Wrapper>
      </div>
    </section>
  )
}

export default Blockquote
