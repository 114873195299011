import React, { useRef, useEffect, useState } from 'react'
import Wrapper from '../Wrap'
import Team from '../../images/process/team.png'
import Governance from '../../images/process/governance_2.png'
import Partners from '../../images/process/partners.png'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const HowWeDoIt = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    const ease = 'power4.easeInOut'
    const bouncingEaseIntro = 'power1.easeInOut'
    const teamRotation = 90
    let firstRun = true
    const unselectedOpacity = 0.25
    const speed = 0.5

    revealRefs.current.forEach((el, index) => {
      const howWeDoItImagesTL = gsap.timeline({
        paused: true,
        repeat: -1,
        scrollTrigger: {
          id: `howWeDoIt-${index + 1}`,
          trigger: el,
          start: 'top 80%',
          end: 'top top',
          once: true,
        },
      })
      const howWeDoItTL = gsap.timeline({
        paused: true,
        repeat: -1,
        scrollTrigger: {
          id: `howWeDoIt-${index + 1}`,
          trigger: el,
          start: 'top 80%',
          end: 'top top',
          once: true,
        },
      })

      const childrenText = el.children[0].children[0].children[0]
      const childrenImages = el.children[0].children[0].children[1]

      const partnerImage = childrenImages.querySelector('.js-partners-image')
      const governanceImage = childrenImages.querySelector(
        '.js-governance-image'
      )
      const teamImageOne = childrenImages.querySelector('.js-team-image-one')
      const teamImageTwo = childrenImages.querySelector('.js-team-image-two')
      const teamImageThree = childrenImages.querySelector(
        '.js-team-image-three'
      )
      const teamImageFour = childrenImages.querySelector('.js-team-image-four')

      howWeDoItImagesTL
        .to(partnerImage, {
          rotation: -360,
          duration: speed * 40,
          ease: 'none',
        })
        .to(
          [teamImageOne, teamImageTwo, teamImageThree, teamImageFour],
          {
            rotation: 360,
            duration: speed * 40,
            ease: 'none',
          },
          '0'
        )

      howWeDoItTL
        .set('.how-we-do-it__partners', { opacity: 1 })
        .set(
          [
            '#desc1, #desc2, #desc3',
            teamImageOne,
            teamImageTwo,
            teamImageThree,
            teamImageFour,
          ],
          { opacity: unselectedOpacity }
        )
        .addLabel('part1', '+=0.5')
        .to(
          governanceImage,
          {
            opacity: 1,
            scale: 1,
            startAt: { scale: 0.5 },
            duration: speed,
            onStart: () => {
              firstRun = false
            },
            ease: 'back.easeInOut',
          },
          'part1'
        )
        .to('#desc1', { opacity: 1, duration: speed, ease: ease }, 'part1')
        .addLabel('part2', '+=0.5')
        .from(
          [teamImageOne, teamImageTwo, teamImageThree, teamImageFour],
          { scale: 0, opacity: 1, duration: speed, ease: 'back.easeInOut' },
          'part2'
        )
        .to(
          '#desc1',
          { opacity: unselectedOpacity, duration: speed, ease: ease },
          'part2'
        )
        .to('#desc2', { opacity: 1, duration: speed, ease: ease }, 'part2')
        .addLabel('part3', '+=0.5')
        .to(
          partnerImage,
          {
            opacity: 1,
            scale: 1,
            startAt: { scale: 0 },
            duration: speed,
            ease: 'back.easeInOut',
          },
          'part3'
        )
        .to(
          '#desc2',
          { opacity: unselectedOpacity, duration: speed, ease: ease },
          'part3'
        )
        .to('#desc3', { opacity: 1, duration: speed, ease: ease }, 'part3+=0.2')
        .addLabel('part4', '+=0.5')
        .to('#desc1', { opacity: 1, duration: speed, ease: ease }, 'part4+=0.1')
        .to('#desc2', { opacity: 1, duration: speed, ease: ease }, 'part4')
        .addLabel('part5', '+=3')
        .to(
          '#desc1, #desc2, #desc3',
          { opacity: unselectedOpacity, duration: speed },
          'part5'
        )
        .to(
          [teamImageOne, teamImageTwo, teamImageThree, teamImageFour],
          { scale: 0, opacity: 0, duration: speed, ease: 'back.easeInOut' },
          'part5'
        )
        .to(
          governanceImage,
          {
            scale: 0,
            opacity: 1,
            duration: speed * 1.5,
            ease: 'back.easeInOut',
          },
          'part5'
        )
        .to(
          partnerImage,
          { scale: 0, opacity: 1, duration: speed, ease: 'back.easeInOut' },
          'part5'
        )
    })
  }, [])

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  return (
    <section className="module -theme-black -pad-top-none">
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <div ref={addToRefs}>
        <Wrapper className="how-we-do-it typ">
          <div className="how-we-do-it__wrapper">
            <div className="how-we-do-it__text-container">
              <p id="desc1" className="how-we-do-it__text">
                Central organising idea
              </p>
              <p id="desc2" className="how-we-do-it__text">
                Integrated specialist teams
              </p>
              <p id="desc3" className="how-we-do-it__text">
                Best in class external partners
              </p>
            </div>
            <div className="how-we-do-it__shapes-container">
              <div className="how-we-do-it__partners">
                <img
                  className="how-we-do-it__partners-img || js-partners-image"
                  src={Partners}
                  alt="Partners"
                />
                <div className="how-we-do-it__governance">
                  <img
                    className="how-we-do-it__governance-img || js-governance-image"
                    src={Governance}
                    alt="Governance"
                  />
                  <div className="how-we-do-it__teams">
                    <div
                      id="team-circle1"
                      className="how-we-do-it__team-circle -one">
                      <img
                        className="how-we-do-it__team-circle-img || js-team-image-one"
                        src={Team}
                        alt="Team"
                      />
                    </div>
                    <div
                      id="team-circle2"
                      className="how-we-do-it__team-circle -two">
                      <img
                        className="how-we-do-it__team-circle-img || js-team-image-two"
                        src={Team}
                        alt="Team"
                      />
                    </div>
                    <div
                      id="team-circle3"
                      className="how-we-do-it__team-circle -three">
                      <img
                        className="how-we-do-it__team-circle-img || js-team-image-three"
                        src={Team}
                        alt="Team"
                      />
                    </div>
                    <div
                      id="team-circle4"
                      className="how-we-do-it__team-circle -four">
                      <img
                        className="how-we-do-it__team-circle-img || js-team-image-four"
                        src={Team}
                        alt="Team"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </section>
  )
}

export default HowWeDoIt
