import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import GatsbyLink from '../GatsbyLink'
import Wrapper from '../Wrap'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const GeneralContent = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      const children = el.children[0].children
      gsap.fromTo(
        children,
        {
          autoAlpha: 0,
          y: 60,
        },
        {
          autoAlpha: 1,
          y: 0,
          ease: 'power4.out',
          stagger: 0.2,
          duration: 1.5,
          scrollTrigger: {
            id: `general-content-${index + 1}`,
            trigger: children,
            start: 'top 80%',
            end: 'top top',
            once: true,
          },
        }
      )
    })
  }, [])

  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  const theme = slice.primary.theme_colour
    ? slice.primary.theme_colour
    : 'black'
  const moduleClasses = `module -theme-${theme}`
  return (
    <section className={moduleClasses}>
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <div ref={addToRefs}>
        <Wrapper className="general-content typ">
          <RichText
            render={slice.primary.content.raw || []}
            serializeHyperlink={GatsbyLink}
          />
        </Wrapper>
      </div>
    </section>
  )
}

export default GeneralContent
