import React, { useRef, useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import SectionFullHeight from '../SectionFullHeight'
import { ResponsivePlayer } from '../ResponsiveVideo'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const VideoEmbed = ({ slice }) => {
  const revealRefs = useRef([])
  revealRefs.current = []

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger)
    }

    revealRefs.current.forEach((el, index) => {
      const wrapper = el.querySelector('.video-embed__content')
      const title = el.querySelector('.video-embed__title')
      const description = el.querySelector('.video-embed__description')

      if (wrapper) {
        if (title) {
          gsap.set(title, {
            autoAlpha: 0,
            y: 40,
          })
        }
        if (description) {
          gsap.set(description, {
            autoAlpha: 0,
            y: 40,
          })
        }
        if (title) {
          gsap.to(title, {
            autoAlpha: 1,
            y: 0,
            ease: 'power4.out',
            stagger: 0.1,
            duration: 1.5,
            scrollTrigger: {
              id: `video-embed-title-description-${index + 1}`,
              trigger: title,
              start: 'top 100%',
              once: true,
            },
          })
        }
        if (description) {
          gsap.to(description, {
            autoAlpha: 1,
            y: 0,
            ease: 'power4.out',
            stagger: 0.1,
            duration: 1.5,
            scrollTrigger: {
              id: `video-embed-title-description-${index + 1}`,
              trigger: description,
              start: 'top 100%',
              once: true,
            },
          })
        }
      }
    })
  }, [])

  const videoURL = slice.primary.video.embed_url
    ? slice.primary.video.embed_url
    : ''
  const videoID = videoURL.match(
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
  )
  const hash = slice.primary.url_hash.raw[0]
    ? slice.primary.url_hash.raw[0].text
    : ''
  const theme = slice.primary.theme_colour
    ? slice.primary.theme_colour
    : 'black'
  const moduleClasses = `module video-module -theme-${theme}`

  return (
    <SectionFullHeight className={moduleClasses}>
      {slice.primary.url_hash.raw[0] ? (
        <span className="anchor" id={hash}></span>
      ) : (
        ''
      )}
      <div className="video-embed -contained typ" ref={addToRefs}>
        <div className="video-embed__video">
          <ResponsivePlayer url={videoID[1]} />
        </div>
        {slice.primary.video_title.text ||
        slice.primary.video_description.text ? (
          <div className="video-embed__content">
            {slice.primary.video_title.text ? (
              <div className="video-embed__title">
                <RichText render={slice.primary.video_title.raw || []} />
              </div>
            ) : (
              ''
            )}
            {slice.primary.video_description.text ? (
              <div className="video-embed__description">
                <RichText render={slice.primary.video_description.raw || []} />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </SectionFullHeight>
  )
}

export default VideoEmbed
