import React from 'react'
import { use100vh } from 'react-div-100vh'
import styled from 'styled-components'

const Section100vh = styled.div`
  display: flex;
  overflow: hidden;
`

const SectionFullHeight = ({ children, className }) => {
  const height = use100vh()
  const browserHeight = height ? height - 70 : '100vh - 70px'
  return (
    <Section100vh className={className} style={{ minHeight: browserHeight }}>
      {children}
    </Section100vh>
  )
}

export default SectionFullHeight
