import React from 'react'
import {
  InfoWithImage,
  TextInfo,
  GeneralContent,
  Blockquote,
  VideoEmbed,
  DNA,
  HeroImageWithText,
  HowWeDoIt,
  LogoList,
  ImageWall,
  TwoColumnText,
  FullWidthImage,
} from './slices'

const SliceZone = ({ slices }) => {
  const sliceComponents = {
    info_with_image: InfoWithImage,
    text_info: TextInfo,
    general_content: GeneralContent,
    blockquote: Blockquote,
    video_embed: VideoEmbed,
    dna: DNA,
    hero_image_with_text: HeroImageWithText,
    how_we_do_it: HowWeDoIt,
    logo_list: LogoList,
    image_wall: ImageWall,
    two_column_text: TwoColumnText,
    full_width_image: FullWidthImage,
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]
    if (SliceComponent) {
      return <SliceComponent slice={slice} key={`slice-${index}`} />
    }
    return null
  })
}

export default SliceZone
